import axios from "axios";
import { useStore } from "vuex";

class CursosService {

  url = useStore().state.api_url;

  headers = {
    "Content-Type": "multipart/form-data",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/courses",
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async addRow(datos) {
    const res = axios({
      url: this.url + "/api/courses",
      method: "POST",
      headers: this.headers,
      data: datos,
    });

    return res;
  }

  async EditRow(id, datos) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "PUT",
      headers: this.headers,
      data: datos,
    });

    return res;
  }

  async DeleteRow(id) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "DELETE",
      headers:{
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      },
    });

    return res;
  }
}

export default CursosService;
