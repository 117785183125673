import axios from "axios";
import { useStore } from "vuex";

class DashboardService {
  
  url = useStore().state.api_url;
  
  headers = {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  async getData() {
    const res = axios({
      url: this.url + "/api/Admins/Dashboard",
      method: "GET",
      headers: this.headers,
    });

    return res;
  }
  
}

export default DashboardService;
