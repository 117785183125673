import axios from "axios";
import { useStore } from "vuex";

class LibrosService {
  
  url = useStore().state.api_url;

  headers = {
    "Content-Type": "multipart/form-data",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };


  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/books",
      method: "GET",
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/books/" + id,
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async addRow(data) {
    const res = axios({
      url: this.url + "/api/books",
      method: "POST",
      headers: this.headers,
      data:data,
    });

    return res;
  }

  async EditRow(id, data) {
    const res = axios({
      url: this.url + "/api/books/" + id,
      method: "PUT",
      headers: this.headers,
      data: data,
    });

    return res;
  }

  async DeleteRow(id) {
    const res = axios({
      url: this.url + "/api/books/" + id,
      method: "DELETE",
      headers:{
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      },
    });

    return res;
  }
}

export default LibrosService;
