import axios from "axios";
import { useStore } from "vuex";

class ReportesService {
  
  url = useStore().state.api_url;

  headers = {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {  }

  async getAfiliacion(datos) {
    const res = axios({
      url: this.url + "/api/Reports/Afiliacion",
      headers:this.headers,
      method: "POST",
      data:JSON.stringify(datos)
    });

    return res;
  }

  async getIngresos(datos) {
    const res = axios({
      url: this.url + "/api/Reports/Ingresos",
      headers:this.headers,
      method: "POST",
      data:JSON.stringify(datos)
    });

    return res;
  }

  async getEgreso(datos) {
    const res = axios({
      url: this.url + "/api/Reports/Egreso",
      headers:this.headers,
      method: "POST",
      data:JSON.stringify(datos)
    });

    return res;
  }

  async getRetiros(datos) {
    const res = axios({
      url: this.url + "/api/Reports/Retiros",
      headers:this.headers,
      method: "POST",
      data:JSON.stringify(datos)
    });

    return res;
  }

  async getBalance() {
    const res = axios({
      url: this.url + "/api/Reports/Balance",
      headers:this.headers,
      method: "POST",
    });

    return res;
  }

}

export default ReportesService;
