<template>
  <div>
    <v-row justify="center">
      <v-dialog class="sizeModal" v-model="show" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Información Detallada</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Titulo"
                    :model-value="props.dataEdit.titulo"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Descripcion"
                    :model-value="props.dataEdit.descripcion"
                    variant="solo"
                    readonly
                  ></v-textarea>
                </v-col>

                <!--<v-col cols="12">
                  <v-text-field
                    label="Archivo"
                    :model-value="props.dataEdit.archivo"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>-->

                <v-col cols="12">
                  <v-textarea
                    label="Cuerpo"
                    :model-value="props.dataEdit.cuerpo"
                    variant="solo"
                    readonly
                    rows="4"
                    row-height="30"
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    prepend-icon="mdi-file-eye"
                    size="large"
                    color="orange-darken-2"
                    :href="urlLibro"
                    target="_blank"
                  >
                    Ver Archivo
                  </v-btn>
                </v-col>

                <v-col cols="12" class="center">
                  <v-img
                    label="Imagen"
                    variant="solo"
                    readonly
                    :width="300"
                    aspect-ratio="16/9"
                    cover
                    :src="urlImagen"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-danger"
              color="white"
              variant="text"
              @click="close()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onUpdated, ref } from "vue";
import { useStore } from "vuex";

//:href="`https://apirpm.solopruebas.nl/resources/${libro}`"
//:src="`https://apirpm.solopruebas.nl/resources/${imagen}`"
const store = useStore();

let imagen = ref("");
let libro = ref("");

let urlImagen = ref("");
let urlLibro = ref("");

const show = computed({
  // getter
  get() {
    return props.banView;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //emit("changeView")
  },
});

const props = defineProps(["banView", "dataEdit"]);
const emit = defineEmits(["changeView"]);

const close = () => {
  emit("changeView");
};

onUpdated(() => {
  let text = props.dataEdit.imagen;
  let myArray = text.split("/");
  imagen.value = myArray[myArray.length - 1];

  urlImagen.value = store.state.api_url + "/resources/"+imagen.value;;

  text = props.dataEdit.archivo;
  myArray = text.split("/");
  libro.value = myArray[myArray.length - 1];

  urlLibro.value = store.state.api_url + "/resources/"+libro.value;;
  //console.log(libro.value);
});
</script>

<style scoped>
.center {
  text-align: center;
  align-content: center;
  align-items: center;
}
</style>
