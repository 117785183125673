import axios from "axios";
import { useStore } from "vuex";

class UserService {

  url = useStore().state.api_url;
  
  headers = {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/UsersAdm/ListUsers",
      method: "GET",
      headers:this.headers
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/UsersAdm/Users/"+id,
      method: "GET",
      headers:this.headers
    });

    return res;
  }

  //Metodo para testig
  async pagar(datos) {
    const res = axios({
      url: this.url + "/api/User/registerPayment",
      method: "POST",
      headers:this.headers,
      data:JSON.stringify(datos)
    });

    return res;
  }

  async updateData(datos) {
    const res = axios({
      url: this.url + "/api/Withdrawal",
      method: "PUT",
      headers:this.headers,
      data:datos
    });

    return res;
  }
  
}

export default UserService;
