<script setup>
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";
import SolicitudesService from "../services/SolicitudesService";

import tableSolicitudesComponent from "./componentes/tableSolicitudesComponent.vue";

const service = new SolicitudesService();

let selected = ref([]);
let datos = ref([]);
let headers = [
  {
    title: "#",
    align: "center",
    key: "id",
  },
  { title: "USUARIO", align: "center", key: "usuario" },
  { title: "SALDO A RETIRAR", align: "center", key: "cantidad" },
  { title: "NO. CUENTA", align: "center", key: "numeroCuenta" },
  { title: "FECHA SOLICITUD", align: "center", key: "aperturaFecha" },
  { title: "ESTATUS", align: "center", key: "status" },
];

onBeforeMount(() => {
  //console.log(localStorage.getItem("id"));
  //console.log(localStorage.getItem("jwt"));
  getAll();
});

const getSelected = async (data, option) => {
  if (option == "Aceptada" || option == "Rechazada" || option == "Cancelada") {
    const { value: motivo } = await Swal.fire({
      title: "¿MOTIVO?",
      input: "text",
      inputLabel: "Escribe el motivo",
      inputPlaceholder: "Escribe el motivo...",
      allowOutsideClick: false,
    });
    if (motivo) {
      selected.value = data;
      //console.log("motivo: " + motivo);
      service
        .updateData({
          ids: data,
          razonCierre: motivo,
          status: option,
        })
        .then((response) => {
          if (response.data.success) {
            getAll();
            msg("INFORMACIÓN ENVIADA!", "", "success", 2000);
          } else {
            msg("ERROR!", response.data.message, "error", 2000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      msg("Escribe un motivo sobre el cambio de estatus", "", "warning", 2000);
    }
  } else {
    service
      .updateData({
        ids: data,
        razonCierre: "",
        status: option,
      })
      .then((response) => {
        if (response.data.success) {
          getAll();
          msg("INFORMACIÓN ENVIADA!", "", "success", 2000);
        } else {
          msg("ERROR!", response.data.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

const getExcel = (datos) => {
  //console.log(datos);
  service
    .getExcel({
      ids: datos,
    })
    .then((response) => {
      /*console.log(response);
      console.log(response.data);
      console.log(response.status);*/

      if (response.status == 200) {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "solicitudes.xlsx");
        document.body.appendChild(link);
        link.click();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response, "error", 2000);
    });
};

const getAll = () => {
  service
    .getData()
    .then((response) => {
      console.log(response.data.data);
      if (response.data.success) {
        datos.value = response.data.data;
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>

<template>
  <div class="py-4 container-fluid">
    <tableSolicitudesComponent
      :headers="headers"
      :data="datos"
      @getSelected="getSelected"
      @getExcel="getExcel"
    />
  </div>

  <div class="countdown"></div>
</template>

<style scoped></style>
