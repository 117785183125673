import axios from "axios";
import { useStore } from "vuex";

class AdminServices {

  url = useStore().state.api_url;

  headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/UsersAdm/ListUsersInternal",
      method: "GET",
      headers: this.headers,
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/UsersAdm/UserInternal/"+id,
      method: "GET",
      headers: this.headers,
    });

    return res;
  }

  async addRow(datos) {
    const res = axios({
      url: this.url + "/api/UsersAdm/NewUser",
      method: "POST",
      headers: this.headers,
      data: JSON.stringify(datos),
    });
    return res;
  }

  async updateData(id,datos) {
    const res = axios({
      url: this.url + "/api/UsersAdm/UserInternal/"+id,
      method: "PUT",
      headers: this.headers,
      data: JSON.stringify(datos),
    });
    return res;
  }

  async delete(id) {
    const res = axios({
      url: this.url + "/api/UsersAdm/UserInternal/"+id,
      method: "DELETE",
      headers: this.headers,
    });
    return res;
  }
}

export default AdminServices;
